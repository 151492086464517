// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled, Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useQuery,
  useResponsive,
  usePagination,
  useState,
  usePopover,
  useToast,
  useDrawer,
  useUrlFilters,
  useEffect,
  useDebouncedCallback,
} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime, Phone} from '@supermove/utils';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import IconButton from '@shared/design/components/IconButton';
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import Table from '@shared/design/components/Table';
import Tabs from '@shared/design/components/Tabs';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import UserStatus from '@shared/modules/User/enums/UserStatus';
import UpdateUserStatusForm from '@shared/modules/User/forms/UpdateUserStatusForm';
import useUpdateUserStatusMutation from '@shared/modules/User/hooks/useUpdateUserStatusMutation';
import AdminAppPage from 'modules/App/components/AdminAppPage';
import MobileSearch from 'modules/App/components/MobileSearch';
import SearchBar from 'modules/App/components/SearchBar';

import AssignSuperAdminDrawer from './AssignSuperAdminDrawer';

const HeaderContainer = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const HeaderText = Styled.Text`
  ${Typography.Label}
`;

const Container = Styled.View`
  max-width: 100%;
`;

const PrimaryText = Styled.Text`
  ${Typography.Body}
`;

const TabsContainer = Styled.View`
`;

const getTabDefinitions = () => [
  {
    key: UserStatus.ACTIVE,
    label: 'Active',
    status: UserStatus.ACTIVE,
  },
  {
    key: UserStatus.INACTIVE,
    label: 'Inactive',
    status: UserStatus.INACTIVE,
  },
];

const TabHeader = ({activeTabKey, setUserStatus}) => {
  const tabDefinitions = getTabDefinitions();
  const activeTabIndex = _.findIndex(
    tabDefinitions,
    (definition) => definition.status === activeTabKey,
  );

  return (
    <TabsContainer>
      <Tabs
        tabs={tabDefinitions}
        handlePressTab={({status}) => {
          setUserStatus(status);
        }}
        activeTabIndex={activeTabIndex}
        TabComponent={Tabs.PrimaryTab}
      />
    </TabsContainer>
  );
};

const SuperAdminActionsPopoverButton = ({user, refetch, currentStatus}) => {
  const superAdminActionsPopover = usePopover();
  const successToast = useToast({
    ToastComponent: SuccessToast,
    message: `${user.fullName} ${currentStatus === UserStatus.ACTIVE ? 'deactivated.' : 'reactivated.'}`,
  });

  const updateUserStatusForm = UpdateUserStatusForm.edit(user);

  const {form, submitting, handleSubmit} = useUpdateUserStatusMutation({
    updateUserStatusForm,
    onSuccess: () => {
      successToast.handleToast();
      refetch();
      superAdminActionsPopover.handleClose();
    },
    onError: (errors) => console.log({errors}),
  });

  let actions = [];
  const handleSubmitUpdateUserStatus = () => {
    if (currentStatus === UserStatus.ACTIVE) {
      form.setFieldValue('updateUserStatusForm.status', UserStatus.INACTIVE);
    } else {
      form.setFieldValue('updateUserStatusForm.status', UserStatus.ACTIVE);
    }
    setTimeout(handleSubmit, 0);
  };
  if (currentStatus === UserStatus.ACTIVE) {
    actions = [
      {text: 'Deactivate user', onPress: handleSubmitUpdateUserStatus, color: colors.red.warning},
    ];
  } else {
    actions = [{text: 'Reactivate user', onPress: handleSubmitUpdateUserStatus}];
  }

  return (
    <React.Fragment>
      <ActionMenuPopover popover={superAdminActionsPopover} width={240} actions={actions}>
        <IconButton
          onPress={superAdminActionsPopover.handleToggle}
          isDisabled={submitting}
          color={colors.gray.secondary}
        >
          <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
        </IconButton>
      </ActionMenuPopover>
    </React.Fragment>
  );
};

const SuperAdminsListPageContent = ({
  setCurrentPage,
  currentPage,
  loading,
  refetch,
  data,
  error,
  setUserStatus,
  currentStatus,
  assignSuperAdminDrawer,
  responsive,
}) => {
  const pagination = usePagination({
    currentPage,
    paginationMetadata: _.get(data, 'paginatedList.paginationMetadata'),
    onChangePage: (page) => {
      setCurrentPage(page);
    },
  });

  return (
    <React.Fragment>
      <ScrollView
        horizontal
        contentContainerStyle={{justifyContent: 'center', flex: 1, paddingHorizontal: 24}}
      >
        <Container>
          <Space height={24} />
          <TabHeader activeTabKey={currentStatus} setUserStatus={setUserStatus} />
          <SuperAdminsList
            loading={loading}
            refetch={refetch}
            users={data?.paginatedList?.users}
            hasError={!!error}
            responsive={responsive}
            currentStatus={currentStatus}
          />
          <Space height={24} />
        </Container>
      </ScrollView>
      <PaginationBar pagination={pagination} />
      <Space height={24} />
      <AssignSuperAdminDrawer
        isOpen={assignSuperAdminDrawer.isOpen}
        handleClose={assignSuperAdminDrawer.handleClose}
        refetch={refetch}
        key={assignSuperAdminDrawer.key}
      />
    </React.Fragment>
  );
};

const SuperAdminsListPage = () => {
  const responsive = useResponsive();
  const [isMobileSearchVisible, setIsMobileSearchVisible] = useState(false);
  const toggleMobileSearch = () => setIsMobileSearchVisible((currentValue) => !currentValue);
  const assignSuperAdminDrawer = useDrawer({name: 'Assign Super Admin'});

  const urlFilters = useUrlFilters({
    getRoute: () => '/users/super-admins',
    filterKeys: ['currentPage', 'searchQuery', 'userStatus'],
    navigationLib: 'react-navigation',
  });

  const [query, setQuery] = useState(urlFilters.get('searchQuery'));

  useEffect(() => {
    const initialFilters = urlFilters.getFilters();
    if (!initialFilters.userStatus) {
      urlFilters.handleUpdate({
        ...initialFilters,
        userStatus: UserStatus.ACTIVE,
      });
    }
    if (!initialFilters.currentPage) {
      urlFilters.handleUpdate({
        ...initialFilters,
        currentPage: 1,
      });
    }
  }, [urlFilters]);

  const {data, loading, refetch, error} = useQuery(SuperAdminsListPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      pagination: {
        page: urlFilters.get('currentPage'),
        resultsPerPage: 50,
      },
      ...urlFilters.getFilters(),
    },
  });

  const handlePage = (currentPage) => {
    urlFilters.handleUpdate({currentPage});
  };

  const debouncedFilterUpdate = useDebouncedCallback(urlFilters.handleUpdate, 1000);
  const handleSearch = (searchQuery) => {
    setQuery(searchQuery);
    debouncedFilterUpdate({searchQuery, currentPage: 1});
  };

  const handleCurrentUserStatus = (userStatus) => {
    urlFilters.handleUpdate({userStatus, currentPage: 1});
  };

  return (
    <AdminAppPage
      HeaderElement={
        <HeaderContainer>
          <AdminAppPage.HeaderContainer responsive={responsive} style={{flexDirection: 'row'}}>
            <AdminAppPage.PageHeadingText responsive={responsive}>
              Super Admins
            </AdminAppPage.PageHeadingText>
            <Space style={{flex: 1}} />
            <Row>
              {responsive.desktop ? (
                <SearchBar
                  placeholder={'Search by name, email, or phone number'}
                  setSearchQuery={handleSearch}
                  searchQuery={query}
                  desktopWidth={280}
                />
              ) : (
                <TertiaryButton onPress={toggleMobileSearch}>
                  <Icon source={Icon.MagnifyingGlass} size={16} color={colors.gray.secondary} />
                </TertiaryButton>
              )}
              <Row>
                <Space width={16} />
                <Button
                  text={responsive.desktop ? 'Assign Super Admin' : 'Assign'}
                  iconLeft={Icon.Plus}
                  onPress={assignSuperAdminDrawer.handleOpen}
                  isSmall
                />
              </Row>
            </Row>
          </AdminAppPage.HeaderContainer>
          <MobileSearch
            searchQuery={query}
            setSearchQuery={handleSearch}
            isVisible={isMobileSearchVisible}
            handleClose={() => setIsMobileSearchVisible(false)}
            placeholder={'Search by name, email, or phone number'}
          />
        </HeaderContainer>
      }
    >
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          return (
            <SuperAdminsListPageContent
              setCurrentPage={handlePage}
              currentPage={_.toNumber(urlFilters.get('currentPage'))}
              loading={loading}
              refetch={refetch}
              data={data}
              error={error}
              setUserStatus={handleCurrentUserStatus}
              currentStatus={urlFilters.get('userStatus')}
              assignSuperAdminDrawer={assignSuperAdminDrawer}
              responsive={responsive}
            />
          );
        }}
      </Loading>
    </AdminAppPage>
  );
};

const getColumnDefinitions = ({refetch, currentStatus}) => {
  return [
    // Name
    {
      flex: 3,
      maxWidth: 320,
      minWidth: 250,
      headerContent: () => {
        return <HeaderText numberOfLines={1}>Name</HeaderText>;
      },
      cellContent: ({item: user}) => {
        return <PrimaryText>{user.fullName}</PrimaryText>;
      },
    },
    // Email
    {
      flex: 3,
      maxWidth: 320,
      minWidth: 280,
      headerContent: () => {
        return <HeaderText numberOfLines={1}>Email</HeaderText>;
      },
      cellContent: ({item: user}) => {
        return <PrimaryText>{user.email}</PrimaryText>;
      },
    },
    // Phone Number
    {
      flex: 2,
      maxWidth: 200,
      minWidth: 174,
      headerContent: () => {
        return <HeaderText numberOfLines={1}>Phone Number</HeaderText>;
      },

      cellContent: ({item: user}) => {
        return <PrimaryText>{Phone.display(user.phoneNumber)}</PrimaryText>;
      },
    },
    // Created at
    {
      flex: 2,
      minWidth: 172,
      headerContent: () => {
        return <HeaderText numberOfLines={1}>Created at</HeaderText>;
      },
      cellContent: ({item: user}) => {
        return <PrimaryText>{Datetime.convertToDisplayDatetime(user.createdAt)}</PrimaryText>;
      },
    },
    // Actions
    {
      width: 80,
      headerContent: () => {
        return;
      },
      cellContent: ({item: user}) => {
        return (
          <React.Fragment>
            <Space width={16} />
            <Table.PreventPropagationContainer>
              <SuperAdminActionsPopoverButton
                user={user}
                refetch={refetch}
                key={user.id}
                currentStatus={currentStatus}
              />
            </Table.PreventPropagationContainer>
          </React.Fragment>
        );
      },
    },
  ];
};

const SuperAdminsList = ({users, loading, refetch, hasError, currentStatus}) => {
  return (
    <Table.FixedHeaderScroll
      columnDefinitions={getColumnDefinitions({
        refetch,
        currentStatus,
      })}
      items={users}
      loading={loading}
      emptyStateText={'No super admins to display'}
      hasError={hasError}
      style={{width: '1200px'}}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SuperAdminsListPage.query = gql`
  ${usePagination.fragment}
  ${UpdateUserStatusForm.edit.fragment}

  query SuperAdminsListPage(
    $userStatus: String
    $searchQuery: String
    $pagination: PaginationInput!
  ) {
    ${gql.query}
    viewer {
      id
      isEpd
      firstName
    }
    paginatedList: filteredSuperAdminsPaginatedList(
      userStatus: $userStatus
      searchQuery: $searchQuery
      pagination: $pagination
    ) {
      users: results {
          id
          fullName
          email
          phoneNumber
          createdAt
          ...UpdateUserStatusForm_edit
        }
        paginationMetadata {
          ...usePagination
        }
    }
  }
`;

export default SuperAdminsListPage;
