// Supermove
import {useNavigation, useMountEffect} from '@supermove/hooks';

const CompanyRootPage = () => {
  const {navigator, params} = useNavigation();

  useMountEffect(() => {
    navigator.navigate('CompanyDetailsProductsPage', {slug: params.slug});
  });

  return null;
};

export default CompanyRootPage;
