// Libraries
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useResponsive, useQuery} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import AdminAppPage from 'modules/App/components/AdminAppPage';
import CompanyDetailsNavigation from 'modules/Company/CompanyDetails/CompanyDetailsNavigation';

const Line = Styled.View`
  border-top-width: 4px;
  border-color: ${colors.gray.border};
`;

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const MobileLabelText = Styled.Text`
  ${Typography.Mobile.Label};
`;

const MobileBodyText = Styled.Text`
  ${Typography.Mobile.Body};
`;

const MenuItemContainer = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
  padding-vertical: 12px;
`;

const SectionItem = Styled.View`
  paddingHorizontal: 12px;
  paddingVertical: 12px;
`;

interface MenuItemProps {
  label: string;
  route: string | {name: string; params?: object};
  slug: string;
}

const MenuItem = ({label, route, slug}: MenuItemProps) => {
  const {navigator} = useNavigation();

  return (
    <MenuItemContainer
      activeOpacity={0.8}
      onPress={() => {
        if (typeof route === 'object') {
          navigator.push(route.name, {...route.params, slug});
        } else {
          navigator.push(route, {slug});
        }
      }}
    >
      <MobileBodyText>{label}</MobileBodyText>
      <Icon source={Icon.AngleRight} size={16} color={colors.gray.secondary} />
    </MenuItemContainer>
  );
};

const CompanyDetailsPageMobileNavigation = () => {
  const {navigator, params} = useNavigation();
  const {data} = useQuery(CompanyDetailsPageMobileNavigation.query, {
    fetchPolicy: 'cache-and-network',
    variables: {slug: params.slug},
  });
  const responsive = useResponsive();
  const navigationItems = CompanyDetailsNavigation.getItems();
  const handleBackNavigation = () => {
    navigator.navigate('CompaniesListPage', params);
  };

  return (
    <Container>
      <AdminAppPage.HeaderContainer responsive={responsive}>
        <Space height={8} />
        <TertiaryButton
          onPress={handleBackNavigation}
          text={'Companies'}
          iconLeft={Icon.AngleLeft}
          textColor={colors.blue.interactive}
          isResponsive
        />
        <Space height={12} />
        <AdminAppPage.PageHeadingText responsive={responsive}>
          {data?.organization?.name}
        </AdminAppPage.PageHeadingText>
      </AdminAppPage.HeaderContainer>
      <ScrollView
        contentContainerStyle={{
          paddingBottom: 32,
        }}
      >
        {navigationItems.map(({label, items}, index) => (
          <React.Fragment key={index}>
            {index > 0 && <Line color={colors.gray.border} />}
            <SectionItem>
              <MobileLabelText>{label}</MobileLabelText>
              <Space height={12} />
              {(items || []).map(({label, value}: any, index: number) => (
                <MenuItem key={index} label={label} route={value} slug={params.slug} />
              ))}
            </SectionItem>
          </React.Fragment>
        ))}
      </ScrollView>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompanyDetailsPageMobileNavigation.query = gql`
query CompanyDetailsPage($slug: String!) {
  ${gql.query}
  organization(slug: $slug) {
      id
      name
  }
}
`;

export default CompanyDetailsPageMobileNavigation;
