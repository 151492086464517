// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useHover, useResponsive} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
// @ts-ignore
import TextTooltip from '@shared/modules/App/components/TextTooltip';
import {StepType} from '@shared/modules/App/hooks/useProgress';

const Column = Styled.View`
`;

const StepButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const StepNumberContainer = Styled.View`
  height: ${({size}: {size: string}) => size}px;
  width: ${({size}: {size: string}) => size}px;
  border-radius: 16px;
  background-color: ${({
    isHovered,
    isSubStep,
    isActive,
    isComplete,
  }: {
    isHovered: boolean;
    isSubStep: boolean;
    isActive: boolean;
    isComplete: boolean;
  }) =>
    isHovered
      ? colors.blue.hover
      : isActive || isComplete
        ? colors.blue.interactive
        : isSubStep
          ? colors.gray.border
          : colors.gray.background};
  justify-content: center;
  align-items: center;
  border-width: 2px;
  border-color: ${({
    isHovered,
    isActive,
    isComplete,
  }: {
    isHovered: boolean;
    isActive: boolean;
    isComplete: boolean;
  }) =>
    isHovered
      ? colors.blue.hover
      : isActive || isComplete
        ? colors.blue.interactive
        : colors.gray.border};
`;

const StepNumber = Styled.Text`
  ${Typography.Responsive.Label}
  line-height: 0;
  color: ${({color}: {color: string}) => color};
`;

const StepLabel = Styled.Text`
  ${({vars}: {vars: {isSubStep: boolean}}) =>
    vars.isSubStep ? Typography.Responsive.MicroLabel : Typography.Responsive.Label};
  line-height: 0;
  color: ${({color}: {color: string}) => color};
`;

const ProgressLine = Styled.View`
  height: 24px;
  width: 2px;
  left: 15px;
  background-color: ${({color}: {color: string}) => color};
`;

const Step = ({
  stepKind,
  index,
  isActive,
  isComplete,
  getStepTitle,
  setCurrentStepKind,
  isSelectable,
  isSubStep = false,
}: {
  stepKind: string;
  index: number;
  isActive: boolean;
  isComplete: boolean;
  getStepTitle: (kind: string) => string;
  setCurrentStepKind: (value: string | ((prevVar: string) => string)) => void;
  isSelectable: boolean;
  isSubStep?: boolean;
}) => {
  const responsive = useResponsive();
  const {ref, isHovered} = useHover();
  return (
    <TextTooltip text={isSelectable ? '' : 'Complete previous steps first.'} placement={'top'}>
      <StepButton ref={ref} disabled={!isSelectable} onPress={() => setCurrentStepKind(stepKind)}>
        {isSubStep && <Space width={8} />}
        <StepNumberContainer
          isHovered={isHovered}
          size={isSubStep ? 16 : 32}
          isSubStep={isSubStep}
          isActive={isActive}
          isComplete={isComplete}
        >
          {isComplete ? (
            <Icon source={Icon.Check} size={isSubStep ? 8 : 12} color={colors.white} />
          ) : isSubStep ? null : (
            <StepNumber
              responsive={responsive}
              color={isHovered || isActive ? colors.white : colors.gray.tertiary}
            >
              {index + 1}
            </StepNumber>
          )}
        </StepNumberContainer>
        <Space width={isSubStep ? 16 : 8} />
        <StepLabel
          vars={{isSubStep}}
          responsive={responsive}
          color={
            isHovered
              ? colors.blue.hover
              : isActive
                ? colors.blue.interactive
                : isComplete
                  ? colors.gray.secondary
                  : colors.gray.tertiary
          }
        >
          {getStepTitle(stepKind)}
        </StepLabel>
      </StepButton>
    </TextTooltip>
  );
};

const getProgressLineColor = ({isActive, isComplete}: {isActive: boolean; isComplete: boolean}) =>
  isActive || isComplete ? colors.blue.interactive : colors.gray.border;

const ProgressStepper = ({
  steps,
  currentStep,
  completedSteps,
  getStepTitle,
  setCurrentStepKind,
  nextStepToComplete,
}: {
  steps: StepType[];
  currentStep: string;
  completedSteps: string[];
  getStepTitle: (kind: string) => string;
  setCurrentStepKind: (value: string | ((prevVar: string) => string)) => void;
  nextStepToComplete: string;
}) => {
  return (
    <Column>
      {steps.map((step: StepType, index: number) => {
        const subStepKinds = step.steps?.map((subStep: StepType) => subStep.kind) || [];
        const isActive = currentStep === step.kind || _.includes(subStepKinds, currentStep);
        const isComplete =
          _.includes(completedSteps, step.kind) ||
          (!_.isEmpty(subStepKinds) &&
            _.every(subStepKinds, (subStepKind: string) =>
              _.includes(completedSteps, subStepKind),
            ));
        return (
          <React.Fragment key={index}>
            {index > 0 && <ProgressLine color={getProgressLineColor({isActive, isComplete})} />}
            <Step
              stepKind={step.kind}
              index={index}
              isActive={isActive}
              isComplete={isComplete}
              getStepTitle={getStepTitle}
              setCurrentStepKind={setCurrentStepKind}
              isSelectable={
                isComplete ||
                _.includes([step.kind, ...(step.steps ? step.steps : [])], nextStepToComplete)
              }
            />
            {step.steps &&
              step.steps.map((subStep: StepType, subIndex: number) => {
                const isSubStepActive = currentStep === subStep.kind;
                const isSubStepComplete = _.includes(completedSteps, subStep.kind);
                return (
                  <React.Fragment key={subIndex}>
                    <ProgressLine
                      color={getProgressLineColor({
                        isActive: isSubStepActive,
                        isComplete: isSubStepComplete,
                      })}
                    />
                    <Step
                      isSubStep
                      stepKind={subStep.kind}
                      index={subIndex}
                      isActive={isSubStepActive}
                      isComplete={isSubStepComplete}
                      getStepTitle={getStepTitle}
                      setCurrentStepKind={setCurrentStepKind}
                      isSelectable={
                        isComplete || _.includes([step.kind, subStep.kind], nextStepToComplete)
                      }
                    />
                  </React.Fragment>
                );
              })}
          </React.Fragment>
        );
      })}
    </Column>
  );
};

export default ProgressStepper;
