// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {GlobalDashboardModel} from '@supermove/models';

// App
import WarningModal from '@shared/design/components/Modal/SmallModal/WarningModal';
import useUpdateGlobalDashboardStatusMutation from '@shared/modules/GlobalDashboard/hooks/useUpdateGlobalDashboardStatusMutation';

interface PublishDashboardModalProps {
  globalDashboardForm: GlobalDashboardModel;
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
}

const PublishDashboardModal = ({
  globalDashboardForm,
  isOpen,
  handleClose,
  refetch,
}: PublishDashboardModalProps) => {
  const {form, handleSubmit, submitting} = useUpdateGlobalDashboardStatusMutation({
    globalDashboardForm: {globalDashboardId: globalDashboardForm.id, status: 'ARCHIVED'},
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <WarningModal
      icon={Icon.BoxArchiveSolid}
      isOpen={isOpen}
      title={`Archive report?`}
      message={`This report will be removed from the library, but won't affect customers who have already added this report.`}
      primaryActionText={'Confirm'}
      secondaryActionText={'Cancel'}
      handlePrimaryAction={handleSubmit}
      handleSecondaryAction={handleClose}
    />
  );
};

export default PublishDashboardModal;
