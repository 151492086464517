// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useResponsive, useState} from '@supermove/hooks';
import {UserModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import AdminAppPage from 'modules/App/components/AdminAppPage';
import MobileSearch from 'modules/App/components/MobileSearch';
import SearchBar from 'modules/App/components/SearchBar';
import CreateCompanyModal from 'modules/Company/components/CreateCompanyModal';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const HeaderContainer = Styled.View`
`;

const CompaniesListPageHeader = ({
  viewer,
  searchQuery,
  handleSearch,
  refetch,
}: {
  viewer: UserModel;
  searchQuery: string;
  handleSearch: (query: string) => void;
  refetch: () => void;
}) => {
  const responsive = useResponsive();

  const createCompanyModal = useModal({name: 'Create Company Modal', enableTracking: true});
  const [isMobileSearchVisible, setIsMobileSearchVisible] = useState<boolean>(false);
  const toggleMobileSearch = () => setIsMobileSearchVisible((currentValue) => !currentValue);

  return (
    <React.Fragment>
      <HeaderContainer>
        <AdminAppPage.HeaderContainer responsive={responsive} style={{flexDirection: 'row'}}>
          <AdminAppPage.PageHeadingText responsive={responsive}>
            Companies
          </AdminAppPage.PageHeadingText>
          <Space style={{flex: 1}} />
          <Row>
            {responsive.desktop ? (
              <SearchBar
                placeholder='Search by company name or identifier'
                searchQuery={searchQuery}
                setSearchQuery={handleSearch}
              />
            ) : (
              <TertiaryButton onPress={toggleMobileSearch}>
                <Icon source={Icon.MagnifyingGlass} size={16} color={colors.gray.secondary} />
              </TertiaryButton>
            )}
            {viewer.isEpd && (
              <Row>
                <Space width={16} />
                <Button
                  text={responsive.desktop ? 'Create Company' : 'Create'}
                  iconLeft={Icon.Plus}
                  onPress={createCompanyModal.handleOpen}
                  isSmall
                />
              </Row>
            )}
          </Row>
        </AdminAppPage.HeaderContainer>
        <MobileSearch
          searchQuery={searchQuery}
          setSearchQuery={handleSearch}
          isVisible={isMobileSearchVisible}
          handleClose={() => setIsMobileSearchVisible(false)}
        />
      </HeaderContainer>
      <CreateCompanyModal
        key={createCompanyModal.key}
        isOpen={createCompanyModal.isOpen}
        handleClose={createCompanyModal.handleClose}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompaniesListPageHeader.fragment = gql`
  fragment CompaniesListPageHeader on User {
    id
    isEpd
  }
`;

export default CompaniesListPageHeader;
