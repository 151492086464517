// Libraries
import React from 'react';

// Supermove
import {Icon, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useMemo, useQuery, useState} from '@supermove/hooks';
import {DashboardCategoryModel, TagModel} from '@supermove/models';

// App
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import MultiDropdownInputComponent from '@shared/design/components/MultiDropdownInput';

interface GlobalDashboardFilterModalProps {
  isOpen: boolean;
  selectedCategoryIds: string[];
  selectedTagIds: string[];
  isOnlyDefaultFilterEnabled: boolean;
  handleClose: () => void;
  handleApply: (props: {
    selectedCategoryIds: string[];
    selectedTagIds: string[];
    isOnlyDefaultFilterEnabled: true | undefined;
  }) => void;
}

const GlobalDashboardFilterModal = ({
  isOpen,
  selectedCategoryIds,
  selectedTagIds,
  isOnlyDefaultFilterEnabled,
  handleClose,
  handleApply,
}: GlobalDashboardFilterModalProps) => {
  const [newSelectedCategoryIds, setNewSelectedCategoryIds] = useState(selectedCategoryIds || []);
  const [newSelectedTagIds, setNewSelectedTagIds] = useState(selectedTagIds || []);
  const [isShowOnlyDefaultReports, setIsShowOnlyDefaultReports] = useState(
    !!isOnlyDefaultFilterEnabled,
  );

  const {data} = useQuery(GlobalDashboardFilterModal.query, {
    fetchPolicy: 'cache-and-network',
    skip: !isOpen,
  });

  const sortedCategoryOptions = useMemo(() => {
    const categories: DashboardCategoryModel[] = data?.dashboardCategories || [];
    return categories
      .map((category) => ({
        label: category.name,
        value: category.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [data]);

  const sortedTagOptions = useMemo(() => {
    const tags: TagModel[] = data?.dashboardTags || [];
    return tags
      .map((tag) => ({
        label: tag.name,
        value: tag.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [data]);

  const filtersCount =
    (newSelectedCategoryIds.length > 0 ? 1 : 0) +
    (newSelectedTagIds.length > 0 ? 1 : 0) +
    (isShowOnlyDefaultReports ? 1 : 0);

  return (
    <SmallModal
      isOpen={isOpen}
      isMobileSheet
      title={`Filters (${filtersCount})`}
      handleActionText={'Apply'}
      handleAction={() =>
        handleApply({
          selectedCategoryIds: newSelectedCategoryIds,
          selectedTagIds: newSelectedTagIds,
          isOnlyDefaultFilterEnabled: isShowOnlyDefaultReports ? true : undefined,
        })
      }
      headerActionText={'Clear Filters'}
      headerActionIcon={Icon.Trash}
      handleHeaderAction={() => {
        setNewSelectedCategoryIds([]);
        setNewSelectedTagIds([]);
        setIsShowOnlyDefaultReports(false);
      }}
      handleClose={handleClose}
    >
      <FieldInput
        index={0}
        component={MultiDropdownInputComponent}
        label={'Category'}
        input={{
          options: sortedCategoryOptions,
          name: 'Category',
          value: newSelectedCategoryIds,
          setFieldValue: (name: string, value: string[]) => setNewSelectedCategoryIds(value),
          placeholder: 'Select categories',
          style: {flex: 1},
          isResponsive: true,
          isPortaled: true,
        }}
      />
      <Space height={16} />
      <FieldInput
        index={1}
        component={MultiDropdownInputComponent}
        label={'Tag'}
        input={{
          options: sortedTagOptions,
          name: 'Tag',
          value: newSelectedTagIds,
          setFieldValue: (name: string, value: string[]) => setNewSelectedTagIds(value),
          placeholder: 'Select tags',
          style: {flex: 1},
          isResponsive: true,
          isPortaled: true,
        }}
      />
      <Space height={16} />
      <Checkbox
        isChecked={isShowOnlyDefaultReports}
        handleToggle={() => setIsShowOnlyDefaultReports(!isShowOnlyDefaultReports)}
        label={'Only show default reports'}
        isResponsive
      />
      <Space height={16} />
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
GlobalDashboardFilterModal.query = gql`
  query GlobalDashboardFilterModal {
    ${gql.query}
    dashboardCategories {
        id
        name
    }
    dashboardTags {
        id
        name
    }
  }
`;

export default GlobalDashboardFilterModal;
