// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useQuery, useResponsive, useState} from '@supermove/hooks';
import {GlobalDashboardModel} from '@supermove/models';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import ExploDashboard from '@shared/modules/Reports/components/ExploDashboard';
import OrganizationItem from 'modules/Dashboards/DashboardDetails/components/OrganizationItem';
import SelectOrganizationModal from 'modules/Dashboards/DashboardDetails/components/SelectOrganizationModal';

const Container = Styled.View`
  flex: 1;
`;

const OrganizationSelectButtonContainer = Styled.View`
`;

interface ExploDashboardPanelProps {
  globalDashboard: GlobalDashboardModel;
  viewerId: number;
}

const ExploDashboardPanel = ({globalDashboard, viewerId}: ExploDashboardPanelProps) => {
  const responsive = useResponsive();
  const [selectedOrganizationSlug, setSelectedOrganizationSlug] = useState<string | undefined>(
    'supermove-demo',
  );
  const selectOrganizationModal = useModal({
    name: 'Global Dashboard Details Explo Panel Select Organization Modal',
  });

  const {loading, data} = useQuery(ExploDashboardPanel.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      slug: selectedOrganizationSlug,
    },
  });

  return (
    <React.Fragment>
      <Container style={{display: responsive.desktop ? 'flex' : 'block'}}>
        <OrganizationSelectButtonContainer>
          <SecondaryButton
            isSmall
            iconSize={12}
            iconLeft={Icon.Eye}
            iconRight={Icon.AngleDown}
            text={
              data?.organization?.name
                ? `View as: ${data.organization.name}`
                : `Select an organization`
            }
            onPress={selectOrganizationModal.handleOpen}
          />
        </OrganizationSelectButtonContainer>
        <Loading loading={loading}>
          {() => (
            <React.Fragment>
              <Space height={16} />
              <ExploDashboard
                name={globalDashboard.name}
                viewerId={viewerId}
                exploDashboardEmbedId={globalDashboard.exploDashboardEmbedId}
                exploUserGroupToken={data?.organization?.exploUserGroupToken}
                exploVariables={globalDashboard.exploVariables}
                style={{
                  height: responsive.desktop ? undefined : 564,
                }}
              />
            </React.Fragment>
          )}
        </Loading>
      </Container>
      <SelectOrganizationModal
        selectedOrganizationSlug={selectedOrganizationSlug}
        isOpen={selectOrganizationModal.isOpen}
        handleClose={selectOrganizationModal.handleClose}
        handleOrganizationSelect={(organizationSlug: string) => {
          setSelectedOrganizationSlug(organizationSlug);
          selectOrganizationModal.handleClose();
        }}
      />
    </React.Fragment>
  );
};

ExploDashboardPanel.query = gql`
  ${OrganizationItem.fragment}

  query ExploDashboardPanel($slug: String) {
    ${gql.query}
    organization(slug: $slug) {
      id
      exploUserGroupToken
      ...OrganizationItem
    }
  }
`;

export default ExploDashboardPanel;
