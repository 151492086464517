// Libraries
import React from 'react';

// Supermove
import {useEffect, useModal} from '@supermove/hooks';
import {DashboardCategoryModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import EditableSortableList from '@shared/modules/App/components/EditableSortableList';
import {useEditableSortableListContext} from '@shared/modules/App/components/EditableSortableList/EditableSortableListContext';
import {DashboardCategoryFormType} from '@shared/modules/DashboardCategory/forms/DashboardCategoryForm';
import useCreateDashboardCategoryMutation from '@shared/modules/DashboardCategory/hooks/useCreateDashboardCategoryMutation';
import useUpdateDashboardCategoryMutation from '@shared/modules/DashboardCategory/hooks/useUpdateDashboardCategoryMutation';
import ManageDashboardCategoryDeleteModal from 'modules/Dashboards/components/ManageDashboardCategoryDeleteModal';

const ManageDashboardCategoriesListItem = ({
  index,
  dashboardCategoryForm,
  isDisabled,
  dashboardCategories,
  handleCancelEdit,
  refetch,
}: {
  index: number;
  dashboardCategoryForm: DashboardCategoryFormType;
  isDisabled: boolean;
  dashboardCategories: DashboardCategoryModel[];
  handleCancelEdit: () => void;
  refetch: () => void;
}) => {
  const deleteDashboardCategoryModal = useModal({name: 'Delete Dashboard Category Modal'});

  const {setIndexOfEdit} = useEditableSortableListContext();

  useEffect(() => {
    if (dashboardCategoryForm.dashboardCategoryId === null) {
      setIndexOfEdit(index);
    }
  }, [dashboardCategoryForm, index, setIndexOfEdit]);

  const {
    form: formUpdate,
    handleSubmit: handleUpdate,
    submitting: isUpdating,
  } = useUpdateDashboardCategoryMutation({
    dashboardCategoryForm,
    onSuccess: refetch,
    onError: (errors) => console.log({errors}),
  });

  const {
    form: formCreate,
    handleSubmit: submitCreate,
    submitting: isCreating,
  } = useCreateDashboardCategoryMutation({
    dashboardCategoryForm,
    onSuccess: refetch,
    onError: (errors) => console.log({errors}),
  });

  const handleSubmit = () => {
    if (dashboardCategoryForm.dashboardCategoryId) {
      handleUpdate();
    } else {
      submitCreate();
    }
  };

  const form = dashboardCategoryForm.dashboardCategoryId ? formUpdate : formCreate;

  return (
    <React.Fragment>
      <EditableSortableList.Item
        key={index}
        index={index}
        fieldName={`dashboardCategoryForm.name`}
        form={form}
        isDisabled={isDisabled}
        isEditable
        itemActions={[
          {
            text: 'Delete Category',
            color: colors.red.warning,
            onPress: deleteDashboardCategoryModal.handleOpen,
          },
        ]}
        inputPlaceholder='Enter category name'
        handleNameSet={handleSubmit}
        handleCancelEdit={handleCancelEdit}
      />
      <ManageDashboardCategoryDeleteModal
        dashboardCategoryForm={dashboardCategoryForm}
        dashboardCategories={dashboardCategories}
        isOpen={deleteDashboardCategoryModal.isOpen}
        handleClose={deleteDashboardCategoryModal.handleClose}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

export default ManageDashboardCategoriesListItem;
