// Libraries
import _ from 'lodash';

const getItems = (): any[] => {
  return [
    {
      label: 'Company',
      value: 'COMPANY',
      valueKey: 'section',
      items: [
        {
          label: 'Products',
          value: 'CompanyDetailsProductsPage',
          valueKey: 'route',
        },
      ],
    },
    {
      label: 'Modules',
      value: 'MODULES',
      valueKey: 'section',
      items: [
        {
          label: 'Calendars',
          value: 'CompanyModulesCalendarsPage',
          valueKey: 'route',
        },
        {
          label: 'Communication',
          value: 'CompanyModulesCommunicationPage',
          valueKey: 'route',
        },
        {
          label: 'Equipment & Materials',
          value: 'CompanyModulesEquipmentAndMaterialsPage',
          valueKey: 'route',
        },
        {
          label: 'Inventory',
          value: 'CompanyModulesInventoryPage',
          valueKey: 'route',
        },
        {
          label: 'Payments',
          value: 'CompanyModulesPaymentsPage',
          valueKey: 'route',
        },
        {
          label: 'Storage',
          value: 'CompanyModulesStoragePage',
          valueKey: 'route',
        },
        {
          label: 'Timesheets',
          value: 'CompanyModulesTimesheetsPage',
          valueKey: 'route',
        },
      ],
    },
    {
      label: 'AI Sales Copilot',
      value: 'AI_SALES_COPILOT',
      valueKey: 'section',
      items: [
        {
          label: 'General',
          value: 'CompanyDetailsAiSalesCopilotGeneralPage',
          valueKey: 'route',
        },
      ],
    },
  ];
};

const CompanyDetailsNavigation = {
  getItems,
};

export default CompanyDetailsNavigation;
