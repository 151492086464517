// Libraries
import React from 'react';

// Supermove
import {Loading, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery, useResponsive, Responsive} from '@supermove/hooks';
import {SettingsModel, UserModel} from '@supermove/models';

// App
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import SettingsSection from '@shared/modules/Settings/components/SettingsSection';
import SettingsSectionOption from '@shared/modules/Settings/components/SettingsSectionOption';
import SettingsForm from '@shared/modules/Settings/forms/SettingsForm';
import useToggleSettingsIsEmailAppendViaSupermoveEnabledMutation from '@shared/modules/Settings/hooks/useToggleSettingsIsEmailAppendViaSupermoveEnabledMutation';
import CompanyDetailsPage from 'modules/Company/CompanyDetails/CompanyDetailsPage';

const CommunicationSettingsContainer = Styled.View`
  padding-horizontal: ${({responsive}: {responsive: Responsive}) => (responsive.desktop ? 0 : 16)}px;
`;

const CommunicationSettings = ({
  settings,
  viewer,
  refetch,
}: {
  settings: SettingsModel;
  viewer: UserModel;
  refetch: () => void;
}) => {
  const settingsForm = SettingsForm.edit(settings, {updatedById: viewer.id});
  const {handleSubmit} = useToggleSettingsIsEmailAppendViaSupermoveEnabledMutation({
    settingsForm,
    onSuccess: refetch,
    onError: (errors: any) => console.log({errors}),
  });
  return (
    <SettingsSection title={'Email'}>
      <SettingsSectionOption
        name={'Include Supermove Branding'}
        description={'Append “via Supermove” to the sender name for all emails.'}
        isEnabled={settings.isEmailAppendViaSupermoveEnabled}
        onPress={handleSubmit}
      />
    </SettingsSection>
  );
};

const CompanyModulesCommunicationPage = () => {
  const {params} = useNavigation();
  const responsive = useResponsive();
  const {loading, data, refetch} = useQuery(CompanyModulesCommunicationPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {slug: params.slug},
  });

  return (
    <CompanyDetailsPage
      selectedLabel={'modules/communication'}
      pageTitle={'Communication'}
      pageDescription={
        'Configure email and SMS settings. These changes will apply to the company and, if applicable, its branches.'
      }
    >
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <CommunicationSettingsContainer responsive={responsive}>
            <CommunicationSettings
              settings={data.company.primaryOrganization.settings}
              viewer={data.viewer}
              refetch={refetch}
            />
          </CommunicationSettingsContainer>
        )}
      </Loading>
    </CompanyDetailsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompanyModulesCommunicationPage.query = gql`
  ${SettingsForm.edit.fragment}

  query CompanyModulesCommunicationPage($slug: String!) {
    ${gql.query}
    viewer {
      id
    }
    company(slug: $slug) {
      id
      primaryOrganization {
        id
        settings {
          id
          isEmailAppendViaSupermoveEnabled
          ...SettingsForm_edit
        }
      }
    }
  }
`;

export default CompanyModulesCommunicationPage;
