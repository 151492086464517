// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled, ScrollView, DropdownInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useState, useLazyQuery, useQuery} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import MediumModal from '@shared/design/components/Modal/MediumModal';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import ChatCompletionKind from '@shared/modules/ChatCompletion/enums/ChatCompletionKind';
import ChatCompletionForm from '@shared/modules/ChatCompletion/forms/ChatCompletionForm';
import useGetChatCompletionMutation from '@shared/modules/ChatCompletion/hooks/useGetChatCompletionMutation';
import Field from 'modules/App/components/Field';

const PageContainer = Styled.View`
    flex: 1;
    background-color: ${colors.gray.background};
    padding-horizontal: 16px;
    padding-vertical: 16px;
    flex-direction: row;
`;

const Row = Styled.View`
    flex-direction: row;
    justify-content: space-between;
`;

const JSONText = Styled.Text`
    ${Typography.Label}
`;

const ErrorText = Styled.Text`
    ${Typography.Body}
    color: ${colors.red.warning};
`;

const AI_MODEL_OPTIONS = [
  {label: 'GPT-3.5-Turbo', value: 'gpt-3.5-turbo'},
  {label: 'GPT-4', value: 'gpt-4'},
  {label: 'GPT-4o', value: 'gpt-4o'},
];

const CHAT_COMPLETION_KIND_OPTIONS = [
  {label: 'Call Summary', value: ChatCompletionKind.CALL_SUMMARY},
  {label: 'Call Action Items', value: ChatCompletionKind.CALL_ACTION_ITEMS},
  {label: 'Call Lead Details', value: ChatCompletionKind.CALL_LEAD_DETAILS},
  {label: 'Complete Call Tasks', value: ChatCompletionKind.COMPLETE_CALL_TASKS},
  {label: 'Generate Email', value: ChatCompletionKind.GENERATE_EMAIL},
  {label: 'Generate SMS', value: ChatCompletionKind.GENERATE_SMS},
];

const getTaskTemplatesByKind = (playbookTemplates: any) => {
  const flattenedTaskTemplates = _.flatten(
    playbookTemplates?.map((playbookTemplate: any) =>
      playbookTemplate.taskTemplates.map((taskTemplate: any) => ({
        label: taskTemplate.taskName,
        value: taskTemplate.id,
        actionKind: taskTemplate.actionKind,
      })),
    ),
  );

  const emailTaskTemplates = flattenedTaskTemplates.filter(
    (taskTemplate: any) => taskTemplate.actionKind === 'SEND_EMAIL',
  );
  const smsTaskTemplates = flattenedTaskTemplates.filter(
    (taskTemplate: any) => taskTemplate.actionKind === 'SEND_SMS',
  );

  return {emailTaskTemplates, smsTaskTemplates};
};

interface LoadFromExistingCallModalProps {
  loadFromExistingCallModal: any;
  form: any;
}

const LoadFromExistingCallModal = ({
  loadFromExistingCallModal,
  form,
}: LoadFromExistingCallModalProps) => {
  const [callUuid, setCallUuid] = useState('');
  const [chatCompletionKind, setChatCompletionKind] = useState('');
  const [selectedTaskTemplateId, setSelectedTaskTemplateId] = useState('');
  const [error, setError]: any = useState(null);
  const {loading: loadingTaskTemplates, data: taskTemplateData} = useQuery(
    LoadFromExistingCallModal.taskTemplateQuery,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const {emailTaskTemplates, smsTaskTemplates} = getTaskTemplatesByKind(
    taskTemplateData?.viewer?.organization?.playbookTemplates,
  );
  const [fetchQuery, {loading, data}] = useLazyQuery(LoadFromExistingCallModal.query, {
    fetchPolicy: 'network-only',
    variables: {
      callUuid,
      kind: chatCompletionKind,
      taskTemplateId: 6901,
    },
    onCompleted: (data: any) => {
      if (!data.chatCompletionForUuidAndKind) {
        setError('No data found');
      }
      if (data?.chatCompletionForUuidAndKind) {
        form.setFieldValue(
          'chatCompletionForm.inputSystemMessage',
          data.chatCompletionForUuidAndKind.inputSystemMessage,
        );
        form.setFieldValue(
          'chatCompletionForm.inputUserMessage',
          data.chatCompletionForUuidAndKind.inputUserMessage,
        );
        form.setFieldValue(
          'chatCompletionForm.callFunctionDefinition',
          data?.chatCompletionForUuidAndKind.callFunctionDefinition,
        );
        form.setFieldValue(
          'chatCompletionForm.openAiModelIdentifier',
          data.chatCompletionForUuidAndKind.openAiModelIdentifier || 'gpt-4o',
        );
        loadFromExistingCallModal.handleClose();
      }
    },
  });

  const handleSubmit = () => {
    if (!callUuid || !chatCompletionKind) {
      setError('Call UUID and Chat Completion Kind are required');
      return;
    } else if (
      [ChatCompletionKind.GENERATE_EMAIL, ChatCompletionKind.GENERATE_SMS].includes(
        chatCompletionKind,
      ) &&
      !selectedTaskTemplateId
    ) {
      setError('Task Template is required for email and sms completion kinds');
      return;
    } else {
      fetchQuery({variables: {callUuid, kind: chatCompletionKind}});
    }
  };

  return (
    <MediumModal
      title={'Load from existing call'}
      primaryActionText={'Confirm'}
      handlePrimaryAction={handleSubmit}
      secondaryActionText={'Cancel'}
      handleSecondaryAction={loadFromExistingCallModal.handleClose}
      isOpen={loadFromExistingCallModal.isOpen}
      isSubmitting={loading}
    >
      {error && (
        <React.Fragment>
          <ErrorText>{error}</ErrorText>
          <Space height={16} />
        </React.Fragment>
      )}
      <FieldInput.LabelText>Call UUID</FieldInput.LabelText>
      <FieldInput.TextInput
        value={callUuid}
        onChangeText={setCallUuid}
        placeholder={'Enter call UUID'}
      />
      <Space height={16} />
      <FieldInput.LabelText>Chat Completion Kind</FieldInput.LabelText>
      <FieldInput
        component={DropdownInput}
        input={{
          options: CHAT_COMPLETION_KIND_OPTIONS,
          placeholder: 'Select completion kind',
          isPortaled: true,
          setFieldValue: (name: any, value: any) => {
            setChatCompletionKind(value);
            setSelectedTaskTemplateId('');
          },
          value: chatCompletionKind,
          style: {
            flex: 1,
          },
        }}
      />
      {[ChatCompletionKind.GENERATE_EMAIL, ChatCompletionKind.GENERATE_SMS].includes(
        chatCompletionKind,
      ) && (
        <React.Fragment>
          <Space height={16} />
          <FieldInput.LabelText>Task Template</FieldInput.LabelText>
          <FieldInput
            component={DropdownInput}
            input={{
              options:
                chatCompletionKind === ChatCompletionKind.GENERATE_EMAIL
                  ? emailTaskTemplates
                  : smsTaskTemplates,
              placeholder: 'Select task template',
              isPortaled: true,
              setFieldValue: (name: any, value: any) => {
                setSelectedTaskTemplateId(value);
              },
              value: selectedTaskTemplateId,
              style: {
                flex: 1,
              },
            }}
          />
        </React.Fragment>
      )}
    </MediumModal>
  );
};

const AIFields = () => {
  const loadFromExistingCallModal = useModal({name: 'loadFromExistingCall'});
  const [completionText, setCompletionText] = useState(null);
  const chatCompletionForm = ChatCompletionForm.new();
  const {form, submitting, handleSubmit} = useGetChatCompletionMutation({
    chatCompletionForm,
    onSuccess: (response: any) => {
      try {
        const responseText = response.completion.text;
        setCompletionText(responseText);
      } catch (error) {
        console.log('Error formatting response:', error);
      }
    },
    onError: (errors: any) => {
      console.log(errors);
    },
  });

  const field = 'chatCompletionForm';

  return (
    <React.Fragment>
      <ScrollView style={{flex: 1, padding: 16, border: `1px solid ${colors.gray.border}`}}>
        <Row>
          <Button onPress={handleSubmit} text={'Submit'} />
          <TertiaryButton
            text={'Clear Form'}
            onPress={() => {
              form.resetForm();
              setCompletionText(null);
            }}
          />
        </Row>
        <Space height={16} />
        {submitting && <PageLoadingIndicator />}
        {completionText && <JSONText>{completionText}</JSONText>}
        <Space height={16} />
        <SecondaryButton
          text={'Load from existing call'}
          onPress={loadFromExistingCallModal.handleOpen}
        />
        <Space height={16} />
        <FieldInput.LabelText>Open AI Model</FieldInput.LabelText>
        <Field
          {...form}
          component={DropdownInput}
          name={`${field}.openAiModelIdentifier`}
          input={{
            options: AI_MODEL_OPTIONS,
            placeholder: 'Select one',
            setFieldValue: (name: any, value: any) => {
              form.setFieldValue(name, value);
            },
            style: {
              flex: 1,
            },
          }}
        />
        <Space height={16} />
        <FieldInput.LabelText>Input System Message</FieldInput.LabelText>
        <FieldInput.Memoized
          {...form}
          index={1}
          name={`${field}.inputSystemMessage`}
          isResponsive
          input={{
            placeholder: 'Enter a system message. This is the base contest prompt that we define.',
            multiline: true,
            style: {
              minHeight: 160,
              borderColor: colors.gray.border,
              borderWidth: 1,
              padding: 8,
            },
          }}
        />
        <Space height={16} />
        <FieldInput.LabelText>Input User Message</FieldInput.LabelText>
        <FieldInput.Memoized
          {...form}
          index={1}
          name={`${field}.inputUserMessage`}
          isResponsive
          input={{
            placeholder: 'Enter a user message. This is typically the call transcript.',
            multiline: true,
            style: {
              minHeight: 160,
              borderColor: colors.gray.border,
              borderWidth: 1,
              padding: 8,
            },
          }}
        />
        <Space height={16} />
        <FieldInput.LabelText>Call Function Definition</FieldInput.LabelText>
        <FieldInput.Memoized
          {...form}
          index={1}
          name={`${field}.callFunctionDefinition`}
          isResponsive
          input={{
            placeholder:
              'Enter a function definition. This can be copy pasted from call_functions.py',
            multiline: true,
            style: {
              minHeight: 160,
              borderColor: colors.gray.border,
              borderWidth: 1,
              padding: 8,
            },
          }}
        />
      </ScrollView>
      <LoadFromExistingCallModal
        loadFromExistingCallModal={loadFromExistingCallModal}
        form={form}
      />
    </React.Fragment>
  );
};

const AIPromptTestingPage = () => {
  return (
    <PageContainer>
      <AIFields />
      <AIFields />
    </PageContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
LoadFromExistingCallModal.query = gql`
  query LoadFromExistingCallModal($callUuid: String!, $kind: String!, $taskTemplateId: Int) {
    ${gql.query}
    chatCompletionForUuidAndKind(callUuid: $callUuid, kind: $kind, taskTemplateId: $taskTemplateId) {
      id
      inputSystemMessage
      inputUserMessage
      callFunctionDefinition
      openAiModelIdentifier
    }
  }
`;

LoadFromExistingCallModal.taskTemplateQuery = gql`
  query OrganizationPlaybookTemplates {
    ${gql.query}
    viewer {
      id
      organization {
        id
        playbookTemplates {
          id
          taskTemplates {
            id
            taskName
            actionKind
          }
        }
      }
    }
  }
`;

export default AIPromptTestingPage;
