// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';

// App
import DrawerWithClose from '@shared/design/components/Drawer/DrawerWithClose';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import ManageGlobalDashboardCompaniesDrawerList from 'modules/Dashboards/DashboardDetails/components/ManageGlobalDashboardCompaniesDrawerList';

interface ManageGlobalDashboardCompaniesDrawerProps {
  globalDashboardUuid: string;
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
}

const ManageGlobalDashboardCompaniesDrawer = ({
  globalDashboardUuid,
  isOpen,
  handleClose,
  refetch: refetchDashboard,
}: ManageGlobalDashboardCompaniesDrawerProps) => {
  const {loading, data, refetch} = useQuery(ManageGlobalDashboardCompaniesDrawer.query, {
    skip: !isOpen,
    fetchPolicy: 'cache-and-network',
    variables: {
      globalDashboardUuid,
    },
  });

  return (
    <DrawerWithClose
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'Manage Companies'}
      width={500}
    >
      <Loading loading={!data || loading} as={PageLoadingIndicator}>
        {() => (
          <ManageGlobalDashboardCompaniesDrawerList
            globalDashboardId={data.globalDashboard.id}
            activeOrganizations={data.activeOrganizations}
            dashboards={data.globalDashboard.dashboards}
            refetch={() => {
              refetch();
              refetchDashboard();
            }}
          />
        )}
      </Loading>
    </DrawerWithClose>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ManageGlobalDashboardCompaniesDrawer.query = gql`
  ${ManageGlobalDashboardCompaniesDrawerList.fragment}
  query ManageGlobalDashboardCompaniesDrawer($globalDashboardUuid: String!) {
    ${gql.query}
    globalDashboard(uuid: $globalDashboardUuid) {
        id
        dashboards {
          id
          ...ManageGlobalDashboardCompaniesDrawerList_Dashboard
        }
    }
    activeOrganizations {
      id
      ...ManageGlobalDashboardCompaniesDrawerList_Organization
    }
  }
`;

export default ManageGlobalDashboardCompaniesDrawer;
